<template>
  <section
    id="asuo-rfi-section"
    class="py-lg-space-xxl ms-rfi-section rds-lazy"
    :data-background-image="bgImage"
  >
    <div class="container-lg px-0 px-lg-gutter">
      <div class="row gx-0 border border-grey bg-white">
        <!-- image column -->
        <div class="col-lg-5 rfi-image"></div>
        <!-- rfi column -->
        <div class="col-12 col-lg-7">
          <form id="asuo-rfi" @submit.prevent="handleSubmit" data-cy="rfi-form">
            <!-- step-1: select degree -->
            <div
              class="py-space-md px-space-xs py-md-space-lg px-md-space-sm py-lg-space-xl px-lg-space-xxl"
            >
              <p class="fs-small mb-space-xxs fw-bold">
                Step {{ currentStep }} of 2
              </p>

              <h2 class="h2-medium mb-space-sm">{{ title }}</h2>

              <p class="mb-space-sm">
                To learn more about ASU Online or a specific program, fill out
                the form below and check your email for information on next
                steps.
              </p>

              <p class="mb-space-sm fs-small">* Indicates a required field</p>

              <div id="form-fields" class="mb-lg-space-md">
                <div v-show="currentStep === 1" id="step-1-form-fields">
                  <!-- degree type -->
                  <div id="degree-type-group" class="fw-bold mb-space-sm">
                    <label for="degree-type" class="mb-space-xxs"
                      >Degree type</label
                    >

                    <TypeAheadSelect
                      id="degree-type"
                      label="text"
                      v-model="(form.degreeType as OptionType)"
                      :options="degreeTypeOptions"
                      placeholder="Select a degree type"
                      preselect-opacity="1"
                      @changed="handleDegreeTypeChange"
                      data-cy="rfi-degree-type-filter"
                      class="fw-normal text-dark-1"
                    >
                    </TypeAheadSelect>
                  </div>

                  <!-- interest areas -->
                  <div id="interest-area-group" class="fw-bold mb-space-sm">
                    <label
                      label="Area of interest"
                      label-for="interest-area"
                      class="mb-space-xxs"
                      >Area of interest</label
                    >

                    <TypeAheadSelect
                      id="interest-area"
                      v-model="(form.interestArea as OptionType)"
                      :options="interestAreaOptions"
                      placeholder="Select an interest area"
                      preselect-opacity="1"
                      @changed="handleInterestAreaChange"
                      data-cy="rfi-interest-area-filter"
                      class="fw-normal text-dark-1"
                    >
                    </TypeAheadSelect>
                  </div>

                  <!-- programs -->
                  <div
                    id="programs-group"
                    class="fw-bold position-relative mb-0 pb-space-sm"
                  >
                    <label
                      label="Program(required)"
                      label-for="program"
                      class="mb-space-xxs"
                      >* Program</label
                    >

                    <TypeAheadSelect
                      id="program"
                      label="text"
                      v-model="(form.program as OptionType)"
                      :options="programOptions"
                      placeholder="Select a program"
                      preselect-opacity="1"
                      @changed="handleProgramChange"
                      data-cy="rfi-program-filter"
                      class="fw-normal text-dark-1"
                    >
                    </TypeAheadSelect>
                  </div>

                  <!-- military checkbox -->

                  <div
                    id="military-group"
                    class="form-check ps-0 position-relative mb-0 pb-space-sm d-flex flex-wrap"
                  >
                    <label
                      class="fw-bold form-check-label fs-medium w-100"
                      for="military"
                    >
                      * Have you served in the U.S. military or are you a
                      military dependent?
                    </label>
                    <div class="form-check" v-for="option in militaryOptions">
                      <label class="form-check-label">
                        {{ option.label }}
                        <input
                          class="form-check-input"
                          type="radio"
                          :value="option.value"
                          v-model="form.isMilitary"
                          @click="handleMilitaryClick()"
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div v-show="currentStep === 2" id="step-2-form-fields">
                  <!-- first-name input field -->
                  <div
                    id="first-name-group"
                    class="position-relative mb-0 pb-space-sm d-flex flex-column"
                  >
                    <label for="first-name" class="fw-bold mb-space-xxs"
                      >* First name</label
                    >
                    <input
                      v-model.trim="form.firstName"
                      type="text"
                      id="first-name"
                      placeholder="Enter your first name"
                      data-cy="rfi-first-name"
                      class="form-control rounded-0"
                      :class="{
                        'is-invalid': firstNameErrorMessage,
                      }"
                      @blur="handleFirstNameBlur()"
                    />
                    <div
                      v-if="firstNameErrorMessage"
                      class="invalid-feedback mb-space-xxs"
                    >
                      {{ firstNameErrorMessage }}
                    </div>
                  </div>

                  <!-- last-name input field -->
                  <div
                    id="last-name-group"
                    class="position-relative mb-0 pb-space-sm"
                  >
                    <label for="last-name" class="fw-bold mb-space-xxs"
                      >* Last name</label
                    >
                    <input
                      v-model.trim="form.lastName"
                      type="text"
                      id="last-name"
                      placeholder="Enter your last name"
                      data-cy="rfi-last-name"
                      class="form-control rounded-0"
                      :class="{
                        'is-invalid': lastNameErrorMessage,
                      }"
                      @blur="handleLastNameBlur()"
                    />
                    <div
                      v-if="lastNameErrorMessage"
                      class="invalid-feedback mb-space-xxs"
                    >
                      {{ lastNameErrorMessage }}
                    </div>
                  </div>

                  <!-- email input field -->
                  <div
                    id="email-group"
                    class="position-relative mb-0 pb-space-sm"
                  >
                    <label for="email" class="fw-bold mb-space-xxs"
                      >* Email</label
                    >
                    <input
                      id="email"
                      type="email"
                      placeholder="Enter your email address"
                      v-model.trim="form.email"
                      @input="handleEmailInput()"
                      @blur="handleEmailBlur()"
                      class="form-control rounded-0"
                      :class="{
                        'is-invalid': emailErrorMessage,
                      }"
                      data-cy="rfi-email"
                    />
                    <div
                      v-if="emailErrorMessage"
                      class="invalid-feedback mb-space-xxs"
                    >
                      {{ emailErrorMessage }}
                    </div>
                  </div>

                  <!-- phone number input field -->
                  <div
                    id="phone-number-group"
                    class="position-relative mb-0 pb-space-md"
                  >
                    <label
                      for="asuonline_phone_number"
                      class="fw-bold mb-space-xxs"
                      >* Phone number</label
                    >
                    <ClientOnly>
                      <PhoneInputRfi
                        id="asuonline_phone_number"
                        class="w-100"
                        :class="{ 'is-invalid': phoneErrorMessage }"
                        :error="phoneErrorMessage != ''"
                        v-model.trim="form.phone.number"
                        :no-validator-state="true"
                        @update="handlePhoneUpdate"
                        @blur="handlePhoneBlur"
                      ></PhoneInputRfi>
                    </ClientOnly>
                    <div
                      v-if="phoneErrorMessage"
                      class="invalid-feedback mb-space-xxs"
                    >
                      {{ phoneErrorMessage }}
                    </div>
                  </div>

                  <!-- consent block -->
                  <div class="fs-xs mb-space-sm mb-md-space-md gdpr-links">
                    <text-gdpr text-class="xs"></text-gdpr>
                  </div>
                </div>
              </div>

              <!-- submit block -->
              <div class="row gx-0">
                <div class="col-12">
                  <div
                    class="d-flex align-items-center"
                    :class="submitContainerClass"
                  >
                    <button
                      type="button"
                      aria-label="go to previous step"
                      @click="previousStep"
                      v-show="currentStep === 2"
                      class="text-dark-3 btn p-0"
                      data-cy="rfi-previous-step-button"
                    >
                      <font-awesome-icon
                        icon="arrow-left"
                        size="2x"
                        alt="back icon"
                      ></font-awesome-icon>
                    </button>
                    <button
                      v-show="currentStep === 1"
                      @click="nextStep"
                      :disabled="!programIsValid"
                      type="button"
                      data-cy="rfi-continue-button"
                      class="fw-bold py-space-xxs px-space-md btn btn-secondary"
                    >
                      Continue
                    </button>
                    <button
                      v-show="currentStep === 2"
                      class="fw-bold py-space-xxs px-space-md btn btn-secondary"
                      type="submit"
                      variant="secondary"
                      :disabled="submitDisabled"
                      data-cy="rfi-submit-button"
                    >
                      Submit
                      <div class="d-inline" v-if="showLoader">
                        <span
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span class="sr-only">Loading...</span>
                        <span
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span class="sr-only">Loading...</span>
                        <span
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span class="sr-only">Loading...</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";

import defaultBgImage from "~/assets/img/rfi-bg-overlay.png?url";
import TextGdpr from "~/components/rfi/TextGdpr.vue";
import TypeAheadSelect from "../TypeAheadSelect.vue";
import PhoneInputRfi from "./PhoneInputRfi.vue";

interface OptionType {
  text: string;
  value: string;
}

interface Props {
  title?: string;
  bgImage?: string;
}

const props = withDefaults(defineProps<Props>(), {
  title: "Request information",
  bgImage: defaultBgImage,
});

const currentStep = ref(1);

const {
  form,
  lastNameErrorMessage,
  emailErrorMessage,
  phoneErrorMessage,
  firstNameErrorMessage,
  programIsValid,
  showLoader,
  submitDisabled,
  currentProgramCode,
  currentProgramName,
  degreeTypeOptions,
  handleDegreeTypeChange,
  programOptions,
  interestAreaOptions,
  handleEmailBlur,
  handleEmailInput,
  handleFirstNameBlur,
  handleInterestAreaChange,
  handleLastNameBlur,
  handleMilitaryClick,
  handlePhoneBlur,
  handlePhoneUpdate,
  handleProgramChange,
  handleSubmit,
} = useRfi();

const militaryOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const submitContainerClass = computed(() => {
  return currentStep.value === 1
    ? "justify-content-end"
    : "justify-content-between";
});

const previousStep = () => {
  currentStep.value = currentStep.value - 1;
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    "request info",
    "back arrow"
  );
};

const nextStep = () => {
  if (currentProgramName.value && currentProgramCode.value) {
    analyticsComposable.trackEvent({
      event: "form",
      name: "onclick",
      action: "click",
      type: "click",
      region: "main content",
      section: "request info",
      text: "continue",
      degree_type:
        form.value.degreeType && form.value.degreeType.value
          ? form.value.degreeType.value.toLowerCase()
          : "select a degree type",
      area_of_interest:
        form.value.interestArea && form.value.interestArea.value
          ? form.value.interestArea.value.toLowerCase()
          : "select an interest area",
      program: currentProgramName.value.toLowerCase(),
      program_code: currentProgramCode.value,
      military_service: form.value.isMilitary == false ? "no" : "yes",
    });
    currentStep.value = currentStep.value + 1;
  } else {
    console.error("missing program name or code, when proceeding to next step");
  }
};
</script>

<style lang="scss" scoped>
.form-check-input:checked {
  color: var(--rds-dark-3);
  --rds-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
  --rds-form-check-bg: var(--rds-secondary);
  background-color: var(--rds-secondary);
  border-color: var(--rds-secondary);
}
.form-check {
  gap: 1rem;
}
.gdpr-links {
  :deep(a) {
    text-decoration: underline;
  }
}
.ms-rfi-section {
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}

.rfi-image {
  background-image: url("~/assets/img/rfi-degree-mobile.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  min-height: 200px;
}

div.invalid-feedback {
  margin-top: 0 !important;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .rfi-image {
    min-height: 400px;
    background-image: url("~/assets/img/rfi-degree-tablet.png");
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .rfi-image {
    min-height: 884px;
    background-image: url("~/assets/img/rfi-card-image.jpg");
  }
}
</style>
